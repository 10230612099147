!((document, $) => {
  'use strict';

  $(document).ready(function() {
    // eslint-disable-next-line require-jsdoc
    function getUrlVars() {
      var vars = [];
      var hash;
      var hashes = window.location.href
        .slice(window.location.href.indexOf('?') + 1)
        .split('&');
      for (var i = 0; i < hashes.length; i++) {
        hash = hashes[i].split('=');
        vars.push(hash[0]);
        vars[hash[0]] = hash[1];
      }
      return vars;
    }

    var searchValue = getUrlVars()['search'];
    var typeValue = getUrlVars()['resource_type'];

    if (searchValue !== undefined) {
      $('#resource-search').val(searchValue.replace(/\+/g, ' '));
    }
    $('#resource-type').val(typeValue);
  });
})(document, jQuery);
